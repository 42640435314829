@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://free.bboxtype.com/embedfonts/?family=FiraSans:400,500,600,700,800|FiraSans+Condensed:400,500,600,700,800');

.-z-10 {
  z-index: -10;
}

.font-fira {
  font-family: 'Fira Sans', sans-serif;
}

.font-fira-condensed {
  font-family: 'Fira Sans Condensed', sans-serif;
}

.group:hover .group-hover\:translate-0 {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
}

.group:hover .group-hover\:-translate-y-1 {
  --tw-translate-y: -0.25rem;
}

.small-caps {
  font-variant: small-caps;
}
